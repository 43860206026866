<template>
  <div class="Enter-box">
    <p class="Enterprise-p"><i></i><span>企业认证</span></p>
    <div class="Enterprise">
      <div class="Enterprise-title">
        <span></span>
        <p>企业信息</p>
      </div>
      <div class="Enterprise-Submit">
          <img src="../../../assets/img/hint.png" alt="">
          <p>您的认证已提交，我们正在加速审核中</p>
          <span>审核结果将以短信形式发送到您的手机上，请注意查收！</span>
          <div class="Enterprise-a">
              <a @click="fanhui()" class="Enterprise-back">返回</a>
              <!-- <a @click="jixu()" class="Enterprise-continue">继续添加</a> -->
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {
    fanhui(){
      this.$router.push('/EnteroriseNewly') // 返回
    },
    jixu(){
      this.$router.push('/EnterpriseXin') // 继续
    }
  }
}
</script>

<style scoed lang="scss">
.el-main{
  
  background: #F6F6FA;
}
.Enterprise-p{
  font-size: 18px;
  color: #999999;
  display: flex;
  margin: 26px 0 30px 0;
  span{
    margin: 4px;
  }
  i{
    background: url('../../../assets/img/din.png') no-repeat;
    width: 23px;
    height: 30px;
    display: block;
    margin-right: 11px;
  }
}

.Enterprise{
  width: 100%;
  color: #f6f6f3;
  background: #FEFEFE;
  -moz-box-shadow:0px 0px 10px #e8e8eb; -webkit-box-shadow:0px 0px 10px #e8e8eb; box-shadow:0px 0px 10px #e8e8eb;
  .Enterprise-Submit{
    padding: 293px 0;
    img{
      margin: 0 auto;
      display: block;
    }
    p{
      color: #333333;
      font-size: 20px;
      text-align: center;
      padding: 40px 0 20px 0;
    }
    span{
      color: #999;
      font-size: 16px;
      text-align: center;
      display: block;
    }
    .Enterprise-a {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      a{
        width: 110px;
        height: 40px;
        font-size: 16px;
        border-radius: 10px;
        text-align: center;
        line-height: 40px;
        &:hover{
          cursor: pointer;
        }
      }
      .Enterprise-back{
        background: #dddddd;
        color: #333333;
        margin-right: 120px;
      }
      .Enterprise-continue{
        background: #FF404E;
        color: #fff;
      }
    }
  }
}

</style>
